import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { Image } from "../../components/images.component"
import { colors, percent } from "../structure/theme"
import { ButtonLink } from "../structure/theme/button.component"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors(`grey.g200`)};
  width: 100%;
  padding: 40px 0;
  flex-direction: column;

  ${breakpoint(`medium`)`
    flex-direction: row;
    padding: 20px 0;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 30px 0;

  &:before {
    display: none;
  }

  ${breakpoint(`medium`)`
    padding: 0 74px;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 248px;
      width: 1px;
      background-color: white;
    }
  `}

  ${breakpoint(`small`)`
    width: ${percent(2 / 3)};
    padding: 0 74px;
  `}
`

const ImageWrapper = styled.div`
  margin: auto;
  max-width: 200px;
  width: 100%;
`

const Link = styled(ButtonLink).attrs({
  major: true,
})`
  display: flex;
  align-items: center;
  text-transform: none;
  margin-top: 20px;
  align-self: center;

  ${breakpoint(`medium`)`
    align-self: flex-start;
  `}
`

export default function BigTrustedPartner ({ linkUrl, linkText, image, children }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image file={image} />
      </ImageWrapper>
      <TextWrapper>
        {children}
        <Link href={linkUrl} data-testid="big_trusted_link_eneco">
          {linkText}
        </Link>
      </TextWrapper>
    </Wrapper>
  )
}

BigTrustedPartner.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}
